import { Button, Icons, useMediaQuery } from "@flash-tecnologia/hros-web-ui-v2";

import {
  StyledFooterBar,
  StyledButtonContainer,
  StyledLinkButton,
} from "./styled";

const FooterBar = ({
  nextHandleClick,
  previousHandleClick,
  nextHandleButtonLabel,
  previousHandleButtonLabel,
  nextButtonIcon,
  disabled,
  loading,
}: {
  nextHandleClick: any;
  previousHandleClick: any;
  nextHandleButtonLabel?: any;
  previousHandleButtonLabel?: any;
  nextButtonIcon: any;
  disabled: boolean;
  loading?: any;
}) => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <StyledFooterBar>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <StyledLinkButton
          variant="primary"
          style={{ alignSelf: "center" }}
          onClick={previousHandleClick}
        >
          {previousHandleButtonLabel ? previousHandleButtonLabel : "Voltar"}
        </StyledLinkButton>
        <StyledButtonContainer>
          <Button
            size={isMobile ? "small" : "large"}
            variant="primary"
            style={{ marginLeft: "24px", alignSelf: "center" }}
            onClick={nextHandleClick}
            disabled={!disabled}
            loading={loading}
          >
            {nextHandleButtonLabel ? nextHandleButtonLabel : "Confirmar"}
            {nextButtonIcon ? (
              <Icons
                name={nextButtonIcon}
                fill="transparent"
                color={!disabled ? "#BBAAB5" : "#FFF"}
              />
            ) : (
              <></>
            )}
          </Button>
        </StyledButtonContainer>
      </div>
    </StyledFooterBar>
  );
};

export { FooterBar };
