import { Icons, LinkButton, Tooltip } from "@flash-tecnologia/hros-web-ui-v2";
import { segment } from "@utils";
import { useNavigate } from "react-router-dom";
import {
  StyledBox,
  StyledCircle,
  StyledDiv,
  StyledDivider,
  StyledDivRow,
  StyledText,
  StyledTitle,
} from "./styled";

const ClosedInvoiceInfoBox = ({
  employees,
  price,
  pricePerEmployee,
  endDate,
  billingDate,
}: {
  employees?: any;
  price: string;
  pricePerEmployee: string;
  endDate: string;
  billingDate: string;
}) => {
  return (
    <StyledBox>
      <StyledDiv style={{ gap: "25px" }}>
        <StyledTitle variant="body3" style={{ fontWeight: 700 }}>
          Fatura Fechada
        </StyledTitle>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              justifyContent: "flex-end",
            }}
          >
            {/* <StyledCircle status={type} /> */}
            <StyledTitle variant="headline5">{employees}</StyledTitle>
          </div>

          <StyledText variant="body4" style={{ whiteSpace: "normal" }}>
            {`Colaboradores ativos neste mês`}
          </StyledText>
        </div>
      </StyledDiv>

      <>
        <StyledDiv
          style={{
            gap: "24px",
            marginTop: "12px",
            flexDirection: "row",
            height: "100%",
            display: "flex",
            alignItems: "flex-end",
            paddingBottom: "12px",
          }}
        >
          <StyledDivRow style={{ gap: "41px" }}>
            <StyledDiv>
              <StyledTitle variant="body3" style={{ fontWeight: 700 }}>
                {price || ""}
              </StyledTitle>
              <StyledText variant="body4">Valor cobrado</StyledText>
            </StyledDiv>
            <StyledDiv>
              <StyledTitle variant="body3" style={{ fontWeight: 700 }}>
                {pricePerEmployee || ""}
              </StyledTitle>
              <StyledText variant="body4">Valor por pessoa</StyledText>
            </StyledDiv>
            <StyledDiv>
              <StyledTitle variant="body3" style={{ fontWeight: 700 }}>
                {endDate || ""}
              </StyledTitle>
              <StyledText variant="body4">Data de fechamento</StyledText>
            </StyledDiv>
            <StyledDiv>
              <StyledTitle variant="body3" style={{ fontWeight: 700 }}>
                {billingDate || ""}
              </StyledTitle>
              <StyledText variant="body4">Data de cobrança</StyledText>
            </StyledDiv>
          </StyledDivRow>
        </StyledDiv>
      </>
    </StyledBox>
  );
};

export { ClosedInvoiceInfoBox };
