import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

const StyledBox = styled.div<{ error: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 24px;
  height: 100%;

  border: 1px solid;
  border-color: ${({ error }) => (error ? "#FEC380" : "#EBE6E9")};
  border-radius: 8px;
`;

const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark2};
`;

const StyledSecondary = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary[50]};
`;

const StyledText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  @media (max-width: 1300px) {
    flex-direction: column;
  }
`;

const StyledIcon = styled(Icons)<{ type: string }>`
  background-color: ${({ type }) =>
    type == "pending" || type == "paymentError" ? "#FFECD6" : "#FFE0EF"};
  color: ${({ type }) =>
    type == "pending" || type == "paymentError" ? "#FEA034" : "#FE2B8F"};
  border-radius: 48px;
  height: 32px;
  width: 32px;
  padding: 10px;
`;

export {
  StyledBox,
  StyledTitle,
  StyledText,
  StyledSecondary,
  StyledDiv,
  StyledIcon,
};
