import { trpc } from "@api/client";
import { ClosedInvoiceInfoBox } from "@components/ClosedInvoiceInfoBox";
import { PageHeader } from "@flash-tecnologia/hros-web-ui-v2";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { Grid } from "@mui/material";
import { getMonthEndDate, getMonthStartDate } from "@utils";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Breadcrumb } from "../../components/Breadcrumb";
import {
  StyledBreadcrumbSeparator,
  StyledPageHeaderColumn,
} from "../../components/Breadcrumb/styled";
import { ColabsHistoric } from "../../components/ColabsHistoric";
import { StyledTitle } from "./styled";

export const ColabsPage = () => {
  const navigate = useNavigate();
  const { invoiceId } = useParams<string>();
  const { selectedCompany } = useSelectedCompany();

  const [details, setDetails] = useState([]);
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);

  function formatarMoeda(valor) {
    const valorFormatado = (valor / 100)?.toFixed(2);

    const [parteInteira, parteDecimal] = valorFormatado?.split(".");

    const parteInteiraFormatada = parteInteira?.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      "."
    );

    if (parteInteiraFormatada && parteDecimal) {
      const valorFinalFormatado = `R$ ${parteInteiraFormatada},${parteDecimal}`;
      return valorFinalFormatado || "";
    }
    return "";
  }

  function formatarDataISOParaDDMMAAAA(dataISO) {
    const data = new Date(dataISO);
    const dia = String(data.getDate()).padStart(2, "0");
    const mes = String(data.getMonth() + 1).padStart(2, "0"); // Lembre-se que os meses em JavaScript começam em 0
    const ano = data.getFullYear();

    if (dia && mes && ano) {
      return `${dia}/${mes}/${ano}` || "";
    }

    return "";
  }

  const refToTop = useRef<any>(null);
  useEffect(() => {
    refToTop.current.scrollIntoView({ behavior: "auto" });
  });

  const hasInvoiceId = invoiceId != undefined ? invoiceId : "";

  // const getOpenedInvoiceMutation = trpc.getOpenedInvoice.useMutation({
  //   onSuccess: (data) => {
  //     setDetails(data?.employees);
  //   },
  //   onError: () => ({}),
  // });

  // const handleCallMutation = async () => {
  //   getOpenedInvoiceMutation.mutate({
  //     body: {
  //       companies: [selectedCompany.id],
  //       startDate: "0000/00/00",
  //       endDate: "0000/00/00",
  //       // startDate: getMonthStartDate(),
  //       // endDate: getMonthEndDate(),
  //     },
  //   });
  // };

  // useEffect(() => {
  //   if (hasInvoiceId == "open") {
  //     handleCallMutation();
  //   }
  // }, [hasInvoiceId]);

  let emp = [];
  let invoiceData = {} as any;
  let contract = [] as any;

  switch (true) {
    case hasInvoiceId != undefined && hasInvoiceId != "open" ? true : false:
      const { data, isLoading } = trpc.getInvoiceDetails.useQuery({
        invoiceId: hasInvoiceId,
      });
      emp = data?.employees;
      invoiceData = data;

      const { data: contractData, isLoading: contractLoading } =
        trpc.getContractByCompany.useQuery();

      contract = contractData;
      break;
  }

  useEffect(() => {
    (async () => {
      if (contract) {
        const peopleContract = contract?.find((c) =>
          c?.product?.some((p) => p?.businessUnity == "people")
        );

        const finance = peopleContract?.productFinance?.find(
          (f) => f?.price != ""
        );

        if (finance) {
          setValue(formatarMoeda(finance?.price));
        } else {
          setValue("");
        }
      }
    })();
  }, [contract]);

  const isCurrentBilling = hasInvoiceId == "open" ? true : false;
  return (
    <>
      <div ref={refToTop}>
        <PageHeader style={{ margin: "0px" }}>
          <StyledPageHeaderColumn>
            <Breadcrumb
              breadcrumbs={[
                {
                  onClick: () => {
                    navigate(`/settings`, { state: { tab: 2 } });
                  },
                  label: "Gerenciar empresa",
                  active: true,
                },
                {
                  onClick: () => {
                    navigate(`/billing`, { state: { tab: 2 } });
                  },
                  label: "Financeiro",
                  active: true,
                },
                {
                  onClick: () => ({}),
                  label: "Visualização de colaboradores",
                },
              ]}
              separator={
                <StyledBreadcrumbSeparator size={24} name="IconChevronRight" />
              }
              style={{ marginBottom: "24px" }}
            />
          </StyledPageHeaderColumn>
        </PageHeader>

        <Grid container spacing={3} style={{ padding: "0px 32px 32px 32px" }}>
          <Grid item md={12} style={{ marginBottom: "16px" }}>
            <StyledTitle variant="headline6">
              {isCurrentBilling ? "Visualização" : "Movimentação"} de
              colaboradores
            </StyledTitle>
          </Grid>

          {!isCurrentBilling && (
            <Grid item md={12} xs={12} style={{ paddingBottom: "24px" }}>
              <ClosedInvoiceInfoBox
                price={formatarMoeda(invoiceData?.amount)}
                pricePerEmployee={value}
                employees={emp?.length}
                endDate={formatarDataISOParaDDMMAAAA(
                  invoiceData?.referenceDate
                )}
                billingDate={formatarDataISOParaDDMMAAAA(invoiceData?.dueDate)}
              />
            </Grid>
          )}

          <Grid item md={12} xs={12}>
            <ColabsHistoric
              details={isCurrentBilling ? details : emp}
              isLoading={loading}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};
