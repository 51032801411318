import { StyledBox, StyledTitle } from "./styled";
import { useNavigate } from "react-router-dom";
import { LinkButton } from "@flash-tecnologia/hros-web-ui-v2";

const BillingBox = ({
  type,
  component,
  tab = 0,
  hasContract,
  setOpenEmailModal,
}: {
  type: string;
  component?: any;
  tab?: number;
  hasContract?: boolean;
  setOpenEmailModal?: any;
}) => {
  const navigate = useNavigate();

  const types = {
    subscription: {
      title: "Plano atual",
      text: "Detalhes do plano",
      href: "/contract-manager/plan",
    },
    card: {
      title: "Meio de pagamento atual",
      text: "Alterar",
      href: "/billing/payment-method",
    },
    emptyCard: {
      title: "Insira seu meio de pagamento",
      text: "Inserir",
      href: hasContract
        ? "/people/acquisition/client-acquisition/payment"
        : "/people/acquisition/new-acquisition/payment",
    },
    email: {
      title: "E-mails automáticos enviados para",
      text: "Editar",
    },
  };

  const buttonAction = () => {
    switch (type) {
      case "card":
        return (
          <LinkButton
            variant="primary"
            style={{ alignSelf: "flex-start" }}
            onClick={() => {
              navigate(`${types[type]?.href}`);
            }}
          >
            {types[type].text}
          </LinkButton>
        );
      case "emptyCard":
        return (
          <LinkButton
            variant="primary"
            style={{ alignSelf: "flex-start" }}
            onClick={() => {
              navigate(`${types[type]?.href}`);
            }}
          >
            {types[type].text}
          </LinkButton>
        );

      case "email":
        return (
          <LinkButton
            variant="primary"
            style={{ alignSelf: "flex-start" }}
            onClick={() => {
              setOpenEmailModal(true);
            }}
          >
            {types[type]?.text}
          </LinkButton>
        );

      default:
        return (
          <LinkButton
            variant="primary"
            style={{ alignSelf: "flex-start" }}
            onClick={() => {
              navigate(types[type]?.href, { state: { tab: 1 } });
            }}
          >
            {types[type]?.text}
          </LinkButton>
        );
    }
  };

  return (
    <StyledBox>
      <div>
        <StyledTitle
          variant="body3"
          style={{ fontWeight: 700, marginBottom: "4px" }}
        >
          {types[type]?.title}
        </StyledTitle>
        {component}
      </div>
      <>{buttonAction()}</>
    </StyledBox>
  );
};

export { BillingBox };
