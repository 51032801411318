import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import Cards from "react-credit-cards-2";
import "react-credit-cards-2/dist/es/styles-compiled.css";
import styled from "styled-components";

const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark2};
`;

const StyledText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
`;

const StyledSecondary = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary.dark.dark5};
`;

const StyledErrorIcon = styled(Icons)`
  color: ${({ theme }) => theme.colors.feedback.error.pure};
`;

const StyledCreditCard = styled(Cards)``;

export {
  StyledTitle,
  StyledText,
  StyledSecondary,
  StyledErrorIcon,
  StyledCreditCard,
};
