import { Container, StyledIcon, StyledText, StyledTextDiv } from "./styled";

export const PaymentInfoBox = () => {
  return (
    <>
      <Container>
        <StyledIcon fill="transparent" name="IconInfoCircle" />
        <StyledTextDiv>
          <StyledText variant="body3" style={{ fontWeight: 700 }}>
            Lembre-se
          </StyledText>
          <StyledText variant="body4" style={{ fontWeight: 400 }}>
            {" "}
            Você poderá alterar o método de pagamento na área de{" "}
            <span style={{ fontWeight: 700 }}>Pagamento e Cobranças.</span>
          </StyledText>
        </StyledTextDiv>
      </Container>
    </>
  );
};
