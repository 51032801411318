import styled from "styled-components";
import {
  IconButton,
  Icons,
  Modal,
  TextField,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";

const StyledModal = styled(Modal)`
  && {
    .modal-container {
      border-radius: 16px;
      max-height: 100%;
      max-width: 60%;
    }
    .modal-content-area {
      padding: 56px 0px;
    }
  }
`;

const StyledSubtitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
`;

const StyledText = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary.dark.dark5};
`;

const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark3};
`;

const StyledIcon = styled(Icons)`
  color: ${({ theme }) => theme.colors.secondary.dark.dark5};
  padding: 12px;
  background: rgba(254, 43, 143, 0.16);
  border-radius: 25px;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start
  align-items: center;
  padding: 0px 112px;
  width: 100%;
  gap: 24px
`;

const StyledIconButton = styled(IconButton)`
  position: absolute;
  right: 24px;
  top: 24px;
`;

const StyledHeaderDiv = styled.div`
  display: flex;
  padding: 56px 224px 56px 112px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-bottom: 1px solid #ebe6e9;
`;

const StyledTextField = styled(TextField)`
  width: 100%;
`;

const StyledAdresseeDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-star;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
`;

const HelperTextContainer = styled.div`
  display: flex;
  gap: 5px;

  align-items: center;
  padding-left: 18px;
`;

const ErrorText = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.feedback.error.pure};
    font-size: 12px;
    font-weight: 700;
  }
`;

const StyledIcon2 = styled(Icons)`
  margin-bottom: 8px;
`;

export {
  StyledModal,
  StyledSubtitle,
  StyledTitle,
  StyledText,
  StyledIcon,
  StyledContainer,
  StyledIconButton,
  StyledHeaderDiv,
  StyledTextField,
  StyledAdresseeDiv,
  HelperTextContainer,
  ErrorText,
  StyledIcon2,
};
