import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Divider } from "@mui/material";
import styled from "styled-components";

const StyledBox = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 24px;
  gap: 24px;
  height: 100%;

  border: 1px solid #ebe6e9;
  border-radius: 8px;
`;

const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark2};
`;

const StyledSecondary = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary[50]};
`;

const StyledText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const StyledDivRow = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 1300px) {
    flex-direction: column;
  }
`;

const StyledCircle = styled.div<{ status: string }>`
  display: flex;
  background: ${({ status }) => (status == "open" ? "#015fcb" : "#6B5B66")};
  height: 4px;
  width: 4px;
  border-radius: 50%;
  animation: ${({ status }) => (status == "open" ? "pulse-animation" : "none")}
    2s infinite;

  @keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0 #d4e8ff;
    }
    50% {
      box-shadow: 0 0 0 4px #d4e8ff;
    }
    100% {
      box-shadow: 0 0 0 0 #d4e8ff;
    }
  }
`;

const StyledDivider = styled(Divider)`
  margin-left: 138px;
  @media (max-width: 1300px) {
    margin-left: 0px;
  }
`;

export {
  StyledBox,
  StyledTitle,
  StyledSecondary,
  StyledText,
  StyledDiv,
  StyledDivRow,
  StyledCircle,
  StyledDivider,
};
