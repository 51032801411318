import { LinkButton } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

const StyledFooterBar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  background-color: ${({ theme }) => theme.colors.neutral.light.pure};

  padding: 16px 24px;
  position: relative;
  bottom: 0px;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledLinkButton = styled(LinkButton)``;

export { StyledFooterBar, StyledButtonContainer, StyledLinkButton };
