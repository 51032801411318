import styled, { css } from "styled-components";
import {
  DataGrid,
  SearchField,
  Tag,
  Typography,
  Icons,
  IconButton,
  TagFilter,
  TagCheckboxFilter,
} from "@flash-tecnologia/hros-web-ui-v2";

const StyledSearchField = styled(SearchField)`
  && {
    .MuiFilledInput-root {
      border-radius: 200px;
    }
  }
`;

const StyledDatagrid = styled(DataGrid)``;

const StyledText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark3};
`;

const StyledTableText = styled(Typography)`
  color: #53464f;
  white-space: pre;
`;

const StyledTag = styled(Tag)`
  cursor: auto;
  padding: 4px 12px;
  pointer-events: none;
  white-space: pre;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 34px;
`;

const StyledIcons = styled(Icons)``;

const StyledFlex = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  flex-direction: row;
`;

const SubImageIcon = styled(IconButton)<{ hasNoImage?: boolean }>`
  ${({ hasNoImage }) =>
    hasNoImage
      ? css`
          && {
            &.MuiButtonBase-root {
              background-color: ${({ theme }) => theme.colors.neutral[100]};
              border: 1px solid #ebe6e9;
            }
          }
        `
      : ""}
`;

const StyledFlexRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  text-align: center;
  justify-content: flex-start;
  flex-direction: "row";
`;

const StyledFiltertag = styled(TagFilter)``;

const WhiteSpacePreDiv = styled.div`
  white-space: pre;
`;

const StyledTagCheckboxFilter = styled(TagCheckboxFilter)``;

export {
  StyledSearchField,
  StyledDatagrid,
  StyledText,
  StyledTableText,
  StyledTag,
  StyledDiv,
  StyledIcons,
  StyledFlex,
  SubImageIcon,
  StyledFlexRow,
  StyledFiltertag,
  WhiteSpacePreDiv,
  StyledTagCheckboxFilter,
};
