import { BrowserRouter, Routes, Route } from "react-router-dom";
import { BillingPagePeople } from "../pages/BillingPagePeople";
import { CreditCardPage } from "../pages/CreditCardPage";
import { ColabsPage } from "../pages/ColabsPage";
import { RoutesGuard } from "./routesGuard";

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<RoutesGuard />}>
          <Route path="/billing" element={<BillingPagePeople />} />
          <Route path="/billing/payment-method" element={<CreditCardPage />} />
          <Route path="/billing/invoice/:invoiceId" element={<ColabsPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
