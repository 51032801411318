import { Outlet, Navigate } from "react-router-dom";

import { getFromLS } from "@flash-tecnologia/hros-web-utility";

// import { usePermissions } from "@flash-hros/auth-helper";

export const RoutesGuard = ({ children }: { children?: JSX.Element }) => {
  // const location = useLocation();

  const isLoggedIn = getFromLS("hrosAccessToken");

  // const routes = usePermissions.getState().permissions || [];

  // const isRouteAllowed = routes.some((c) =>
  //   matchPath({ path: c.path, end: true }, location.pathname)
  // );

  if (!isLoggedIn) {
    return <Navigate to={"/authentication/login"} />;
  }

  // if (!isRouteAllowed) {
  //   return <Navigate to={"/home"} />;
  // }

  return children ? children : <Outlet />;
};
