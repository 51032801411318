import { Radio } from "@flash-tecnologia/hros-web-ui-v2";
import { segment } from "@utils";
import {
  Container,
  StyledIcon,
  StyledPaymentMethodDescriptionText,
  StyledPaymentMethodText,
  StyledTextDiv,
} from "./styled";

export const PaymentMethodBox = ({
  checked,
  iconName,
  paymentMethod,
  paymentMethodDescription,
  onPaymentMethodSelect,
  segmentTrack,
}: {
  checked: boolean;
  iconName: any;
  paymentMethod: string;
  paymentMethodDescription: string;
  onPaymentMethodSelect: any;
  segmentTrack: string;
}) => {
  return (
    <>
      <Container checked={checked}>
        <Radio checked={checked} onChange={onPaymentMethodSelect} />
        <StyledIcon fill="transparent" name={iconName} />
        <StyledTextDiv>
          <StyledPaymentMethodText variant="body3" style={{ fontWeight: 700 }}>
            {paymentMethod}
          </StyledPaymentMethodText>
          <StyledPaymentMethodDescriptionText variant="body4">
            {paymentMethodDescription}
          </StyledPaymentMethodDescriptionText>
        </StyledTextDiv>
      </Container>
    </>
  );
};
