import { Checkbox, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  padding: 32px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid #ebe6e9;
`;

export const StyledPaymentMethodTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
`;

export const StyledPaymentMethodInfos = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[40]};
`;

export const StyledPaymentMethodDescriptionText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`;

export const FlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const FlexRowDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
`;

export const FlexColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledCheckBox = styled(Checkbox)`
  & {
    padding: 9px 9px 9px 0px;
  }
`;
