import styled from "styled-components";

import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";

export const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 24px;
  gap: 20px;

  width: 100%;

  border: 1px solid #fec380;
  border-radius: 8px;
`;

export const StyledIcon = styled(Icons)`
  color: ${({ theme }) => theme.colors.feedback.error[50]};
  padding: 12px;
  background: ${({ theme }) => theme.colors.feedback.error[90]};
  border-radius: 48px;
  height: 48px;
  width: 48px;
`;

export const StyledText = styled(Typography)`
  color: ${({ theme }) => theme.colors.feedback.error[10]};
`;

export const StyledFlexRowDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  width: 100%;
`;

export const StyledFlexColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 70%;
`;
