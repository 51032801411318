import { Button, Typography } from "@flash-tecnologia/hros-web-ui-v2";

import {
  StyledModal,
  StyledSubtitle,
  StyledText,
  StyledIcon,
  StyledContainer,
  StyledIconButton,
} from "./styled";

const SuccessModal = ({
  onOpen,
  setOpenModal,
  route,
  title,
  segmentConfirm,
  paymentMethod,
}: {
  onOpen: any;
  setOpenModal: any;
  route: string;
  title: string;
  segmentConfirm?: any;
  paymentMethod?: string;
}) => {
  const modalHeader = (
    <StyledIconButton
      size="small"
      variant="line"
      icon="IconX"
      onClick={() => {
        setOpenModal(false);
        window.location.href = route;
      }}
    />
  );

  const modalFooter = (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        padding: "24px 0px",
        flexDirection: "row-reverse",
        alignItems: "center",
        borderTop: "1px solid #EBE6E9",
      }}
    >
      <Button
        variant="primary"
        size="large"
        onClick={() => {
          setOpenModal(false);
          window.location.href = route;
          segmentConfirm && segmentConfirm();
        }}
      >
        Voltar para o financeiro
      </Button>
    </div>
  );
  return (
    <StyledModal
      header={modalHeader}
      footer={modalFooter}
      open={onOpen}
      onClose={() => ({})}
    >
      <StyledContainer>
        <StyledIcon name="IconCheck" fill="transparent" />
        <Typography
          variant="headline6"
          style={{
            marginTop: "16px",
            marginBottom: "16px",
            textAlign: "center",
          }}
        >
          {title}
        </Typography>
        <StyledSubtitle variant="body3">
          Você escolheu a forma de pagamento{" "}
          <span style={{ fontWeight: 700 }}>{paymentMethod}</span>, que só será
          válida na próxima fatura e os detalhes para realizar o pagamento serão
          enviados por e-mail. Você poderá alterar o método de pagamento
          novamente a partir do próximo mês.
        </StyledSubtitle>
      </StyledContainer>
    </StyledModal>
  );
};

export { SuccessModal };
