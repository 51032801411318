import { Icons, TagDateFilter } from "@flash-tecnologia/hros-web-ui-v2";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GenericProfilePicture } from "../GenericProfilePicture";
import EmptyTableImage from "../../assets/noResults.svg";

import {
  EmailIcon,
  EmailText,
  EmailWrapper,
  FirstColumnCell,
  FirstColumnCellData,
  StyledButton,
  StyledButtonLabel,
  StyledDatagrid,
  StyledDiv,
  StyledEmptyDiv,
  StyledEmptyTable,
  StyledFiltertag,
  StyledFlexRow,
  StyledGridDiv,
  StyledSearchField,
  StyledTableText,
  StyledTag,
  StyledText,
} from "./styled";

const ColabsHistoric = ({ details, isLoading }) => {
  const filterByStatus = [
    {
      label: "Pago",
      value: "paid",
    },
    {
      label: "Em processamento",
      value: "processing",
    },
    {
      label: "Erro no pagamento",
      value: "error",
    },
    {
      label: "Fechado",
      value: "closed",
    },
    {
      label: "Em aberto",
      value: "opened",
    },
  ];
  const mockedEmployees = [
    {
      employee: {
        name: "Vini Sakai",
        contacts: [{ type: "email", value: "vinicius.sakai@flashapp.cpm/br" }],
      },
      role: "Analista de RH",
      department: "Recursos Humanos",
      startDate: "10/01/2023",
      deactivationDate: "10/03/2023",
    },
    {
      employee: {
        name: "Jose",
        contacts: [{ type: "email", value: "vinicius.sakai@flashapp.cpm/br" }],
      },
      role: "Analista de RH",
      department: "Recursos Humanos",
      startDate: "10/04/2023",
      deactivationDate: "10/05/2023",
    },
    {
      employee: {
        name: "Ana",
        contacts: [{ type: "email", value: "vinicius.sakai@flashapp.cpm/br" }],
      },
      role: "Analista de RH",
      department: "Recursos Humanos",
      startDate: "10/06/2023",
      deactivationDate: "10/07/2023",
    },
    {
      employee: {
        name: "uelton",
        contacts: [{ type: "email", value: "vinicius.sakai@flashapp.cpm/br" }],
      },
      role: "Analista de RH",
      department: "Recursos Humanos",
      startDate: "10/08/2023",
      deactivationDate: "10/09/2023",
    },
    {
      employee: {
        name: "Lucas",
        contacts: [{ type: "email", value: "vinicius.sakai@flashapp.cpm/br" }],
      },
      role: "Analista de RH",
      department: "Recursos Humanos",
      startDate: "10/10/2023",
      deactivationDate: "10/11/2023",
    },
  ];

  const [filteredEmployees, setFilteredEmployees] = useState(details);

  const columns = useMemo(
    () => [
      {
        Header: "Colaboradores",
        accessor: "name",
        Cell: ({
          row: {
            original: { name, documentNumber },
          },
        }) => {
          if (name) {
            return (
              <FirstColumnCell>
                <GenericProfilePicture size={40} name={name} source={null} />
                <FirstColumnCellData>
                  <StyledTableText variant="body4">{name}</StyledTableText>
                  {/* {!!email && (
                  <EmailWrapper>
                    <EmailIcon name="IconMail" size={16} fill="transparent" />
                    <EmailText variant="caption">{email}</EmailText>
                  </EmailWrapper>
                )} */}
                  {!!documentNumber && (
                    <EmailWrapper>
                      {/* <EmailIcon name="IconMail" size={16} fill="transparent" /> */}
                      <EmailText variant="caption">{documentNumber}</EmailText>
                    </EmailWrapper>
                  )}
                </FirstColumnCellData>
              </FirstColumnCell>
            );
          } else {
            return (
              <FirstColumnCell>
                <GenericProfilePicture size={40} name={""} source={null} />
                <FirstColumnCellData>
                  <StyledTableText variant="body4">{""}</StyledTableText>
                </FirstColumnCellData>
              </FirstColumnCell>
            );
          }
        },
      },
      // {
      //   Header: "Cargo",
      //   accessor: "role",
      //   Cell: ({
      //     row: {
      //       original: { role = null },
      //     },
      //   }) => {
      //     return (
      //       <StyledFlexRow>
      //         <StyledTableText variant="body3">{role}</StyledTableText>
      //       </StyledFlexRow>
      //     );
      //   },
      // },
      // {
      //   Header: "Departamento",
      //   accessor: "department",
      //   Cell: ({
      //     row: {
      //       original: { department = null },
      //     },
      //   }) => {
      //     return (
      //       <StyledFlexRow>
      //         <StyledTableText variant="body3">{department}</StyledTableText>
      //       </StyledFlexRow>
      //     );
      //   },
      // },
      {
        Header: "Data de entrada",
        accessor: "activationDate",
        Cell: ({
          row: {
            original: { activationDate = null },
          },
        }) => {
          return (
            <StyledFlexRow>
              <StyledTableText variant="body3">
                {activationDate}
              </StyledTableText>
            </StyledFlexRow>
          );
        },
      },
      {
        Header: "Data de saída",
        accessor: "deactivationDate",
        Cell: ({
          row: {
            original: { deactivationDate = null },
          },
        }) => {
          return (
            <StyledFlexRow>
              <StyledTableText variant="body3">
                {deactivationDate}
              </StyledTableText>
            </StyledFlexRow>
          );
        },
      },
    ],
    [filteredEmployees]
  );

  const navigate = useNavigate();

  const onSearch = (value: string) => {
    const result = details?.filter((item) =>
      item?.name.match(RegExp(value, "ig"))
    );
    setFilteredEmployees([...result]);
  };

  useEffect(() => {
    setFilteredEmployees(details);
  }, [details]);

  const filterByDate = ({
    event,
    start = false,
  }: {
    event: any;
    start?: boolean;
  }) => {
    const { from, to } = event;
    if (from == undefined && to == undefined) {
      setFilteredEmployees(details);
    }
    if ((event.from != undefined || event.to != undefined) && details.length) {
      if (start) {
        const filteredEmployees = details?.filter((emp) => {
          if (emp?.activationDate != null || emp?.activationDate != undefined) {
            const convertedDate = emp?.activationDate.split("/");
            const year = convertedDate[2].toString();
            const month = convertedDate[1];
            const day = convertedDate[0];
            const startDate = new Date(year + "/" + month + "/" + day);

            return startDate >= new Date(from) && startDate <= new Date(to);
          } else {
            return;
          }
        });
        setFilteredEmployees(filteredEmployees);
      } else {
        const filteredEmployees = details?.filter((emp) => {
          if (
            emp?.deactivationDate != null ||
            emp?.deactivationDate != undefined
          ) {
            const convertedDate = emp?.deactivationDate.split("/");
            const year = convertedDate[2].toString();
            const month = convertedDate[1];
            const day = convertedDate[0];
            const startDate = new Date(year + "/" + month + "/" + day);
            return startDate >= from && startDate <= to;
          } else {
            return;
          }
        });
        setFilteredEmployees(filteredEmployees);
      }
    }
  };

  return (
    <StyledDiv>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <StyledText variant="headline8" style={{ marginRight: "4px" }}>
          Colaboradores até o momento
        </StyledText>
        <StyledTag variant="gray">
          {details?.length == 1
            ? details?.length + " item" || "0" + " itens"
            : details?.length + " itens" || "0" + " itens"}
        </StyledTag>
      </div>
      <StyledSearchField
        onChange={(e) => {
          onSearch(e.target.value);
        }}
        placeholder="Buscar por colaborador"
        label={"Buscar por colaborador"}
      />
      <StyledGridDiv>
        <StyledTableText variant="body3">Filtrar por </StyledTableText>
        {/* <StyledFiltertag
          disabled={!filteredEmployees?.length ? true : false}
          hasLeftIcon={false}
          onApply={() => {
            // handleFilteredContent(values);
          }}
          onClear={() => {
            // handleFilteredContent([]);
          }}
          options={filterByStatus}
          optionIconType={"checkbox"}
          variant={"secondary"}
          filterLabel={"Cargo"}
        /> */}
        {/* <StyledFiltertag
          disabled={!filteredEmployees?.length ? true : false}
          hasLeftIcon={false}
          onApply={() => {
            // handleFilteredContent(values);
          }}
          onClear={() => {
            // handleFilteredContent([]);
          }}
          options={filterByStatus}
          optionIconType={"checkbox"}
          variant={"secondary"}
          filterLabel={"Departamento"}
        /> */}

        <TagDateFilter
          hasLeftIcon={false}
          variant="secondary"
          filterLabel={"Data de entrada"}
          onSubmit={(e) => {
            filterByDate({ event: e, start: true });
          }}
        />

        <TagDateFilter
          hasLeftIcon={false}
          variant="secondary"
          filterLabel={"Data de saída"}
          onSubmit={(e) => {
            filterByDate({ event: e, start: false });
          }}
        />
      </StyledGridDiv>
      {filteredEmployees?.length ? (
        <StyledDatagrid
          columns={columns as any}
          loading={isLoading}
          data={filteredEmployees || []}
          hasPagination={true}
          pageSizeOptions={[
            { label: "5 itens", value: 5 },
            { label: "10 itens", value: 10 },
            { label: "20 itens", value: 20 },
          ]}
        />
      ) : (
        <StyledEmptyTable>
          <StyledEmptyDiv>
            <EmptyTableImage />
            <StyledTableText variant="body3">
              Você ainda não cadastrou nenhum colaborador
            </StyledTableText>
            {/* <StyledButton
              variant="primary"
              style={{ alignSelf: "center" }}
              onClick={() => {
                navigate(`/employees`);
              }}
              size="large"
            >
              <StyledButtonLabel variant="body3">
                Cadastrar colaboradores
              </StyledButtonLabel>
              <Icons name="Add" color="#fff" />
            </StyledButton> */}
          </StyledEmptyDiv>
        </StyledEmptyTable>
      )}
    </StyledDiv>
  );
};

export { ColabsHistoric };
