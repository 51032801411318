import { MonitoringErrorBoundary } from "@flash-tecnologia/hros-web-utility";

export class ErrorBoundary extends MonitoringErrorBoundary {
  project =
    "https://bf975ec61859459687fc43453dd66ce4@o266934.ingest.sentry.io/4505545664561152";

  constructor(props) {
    super(props);
  }
}
