export const getMonthEndDate = () => {
  const currentDate = new Date();

  currentDate.setMonth(currentDate.getMonth() + 1, 1);

  currentDate.setDate(currentDate.getDate() - 1);

  const dia = currentDate.getDate().toString().padStart(2, "0");
  const mes = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  const ano = currentDate.getFullYear();

  const dataFormatada = `${dia}/${mes}/${ano}`;

  return dataFormatada;
};

export const getMonthStartDate = () => {
  const currentDate = new Date();

  currentDate.setDate(1);

  const dia = currentDate.getDate().toString().padStart(2, "0");
  const mes = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  const ano = currentDate.getFullYear();

  const dataFormatada = `${dia}/${mes}/${ano}`;

  return dataFormatada;
};

function padZero(number) {
  return number.toString().padStart(2, "0");
}

export const getNextMonthFirstWeekday = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();

  const firstDayOfNextMonth = new Date(year, month + 1, 1);

  if (firstDayOfNextMonth.getDay() === 0) {
    firstDayOfNextMonth.setDate(2);
  } else if (firstDayOfNextMonth.getDay() === 6) {
    firstDayOfNextMonth.setDate(3);
  }

  const nextMonth = firstDayOfNextMonth.getMonth() + 1;
  const nextMonthYear = firstDayOfNextMonth.getFullYear();

  const formattedDate = `${padZero(
    firstDayOfNextMonth.getDate()
  )}/${nextMonth}/${nextMonthYear}`;

  return formattedDate;
};
