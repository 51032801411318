import styled, { css } from "styled-components";
import {
  DataGrid,
  SearchField,
  Tag,
  Typography,
  Icons,
  IconButton,
  TagFilter,
  Button,
  DateRangePicker,
} from "@flash-tecnologia/hros-web-ui-v2";

const StyledSearchField = styled(SearchField)`
  && {
    .MuiFilledInput-root {
      border-radius: 200px;
    }
  }
`;

const StyledDatagrid = styled(DataGrid)``;

const StyledText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark3};
`;

const StyledTableText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[40]};
`;

const StyledTag = styled(Tag)`
  cursor: auto;
  padding: 4px 12px;
  pointer-events: none;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const StyledIcons = styled(Icons)``;

const StyledFlex = styled.div`
  display: flex;
  align-items: center;
`;

const SubImageIcon = styled(IconButton)<{ hasNoImage?: boolean }>`
  ${({ hasNoImage }) =>
    hasNoImage
      ? css`
          && {
            &.MuiButtonBase-root {
              background-color: ${({ theme }) => theme.colors.neutral[100]};
              border: 1px solid #ebe6e9;
            }
          }
        `
      : ""}
`;

const StyledFlexRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  text-align: center;
  justify-content: flex-start;
  flex-direction: "row";
`;

const StyledFiltertag = styled(TagFilter)``;

const FirstColumnCell = styled.div`
  display: flex;
  flex-direction: row;
`;

const FirstColumnCellData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin-left: 10px;
`;

const EmailIcon = styled(Icons)`
  color: var(--color-neutral-70);
`;

const EmailWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;
const EmailText = styled(Typography)`
  color: var(--color-neutral-60);
`;

const StyledButton = styled(Button)``;

const StyledButtonLabel = styled(Typography)`
  && {
    font-weight: 700;
  }
`;

const StyledEmptyTable = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;

  width: 100%;
  height: 600px;

  background: #f8f6f8;

  border: 1px solid #ebe6e9;
  border-radius: 12px;
`;

const StyledEmptyDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 24px;
`;

const StyledDateRangePicker = styled(DateRangePicker)``;

const StyledGridDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  @media (max-width: 700px) {
    display: grid;
    grid-template-columns: 100%% 50% 50%;
  }
`;

export {
  StyledSearchField,
  StyledDatagrid,
  StyledText,
  StyledTableText,
  StyledTag,
  StyledDiv,
  StyledIcons,
  StyledFlex,
  SubImageIcon,
  StyledFlexRow,
  StyledFiltertag,
  FirstColumnCell,
  FirstColumnCellData,
  EmailIcon,
  EmailWrapper,
  EmailText,
  StyledButton,
  StyledButtonLabel,
  StyledEmptyTable,
  StyledEmptyDiv,
  StyledDateRangePicker,
  StyledGridDiv,
};
