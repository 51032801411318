import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { PageHeader } from "@flash-tecnologia/hros-web-ui-v2";
import {
  getFromLS,
  // getAccessToken,
  useSelectedCompany,
} from "@flash-tecnologia/hros-web-utility";
import { Authentication } from "@flash-hros/auth-helper";
const { getAccessToken } = Authentication;

import { Grid } from "@mui/material";
import { Breadcrumb } from "../../components/Breadcrumb";
import {
  StyledBreadcrumbSeparator,
  StyledPageHeaderColumn,
} from "../../components/Breadcrumb/styled";
import { FooterBar } from "../../components/FooterBar";
import { StyledSecondary, StyledText, StyledTitle } from "./styled";
import { dispatchToast, segment } from "../../utils";

import { trpc } from "../../api/client";
import { PaymentMethodBox } from "@components/PaymentMethodBox";
import { paymentMethods } from "../../mocks";
import { CreditCardForm } from "@components/CreditCardForm";
import { PaymentMethodDescriptionBox } from "@components/PaymentMethodDescriptionBox";
import { SuccessModal } from "@components/SuccessModal";
import { ConditionsContract } from "@components/ConditionsContractModal";

const CreditCardPage = () => {
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);
  const [paymentMethod, setPaymentMethod] = useState<string>("creditCard");
  const [openConditionsModal, setOpenConditionsModal] =
    useState<boolean>(false);
  const [acceptTerms, setAcceptTerms] = useState<boolean>(false);

  const { selectedCompany } = useSelectedCompany();
  const navigate = useNavigate();
  const cardInfosRef = useRef<any>(null);
  const refToTop = useRef<any>(null);

  const {
    data: dataV2,
    isError: isErrorV2,
    isLoading: isLoadingV2,
  } = trpc.getContractByCompanyV2.useQuery();

  const template = dataV2?.contractTemplate;

  useEffect(() => {
    refToTop.current.scrollIntoView({ behavior: "auto" });
  }, []);

  useEffect(() => {
    if (dataV2?.paymentMethod == "Cartão de crédito") {
      setPaymentMethod("creditCard");
    } else {
      setPaymentMethod("billet");
    }
  }, [dataV2]);

  const updateContractSetup = trpc.updateContractSetup.useMutation({
    onSuccess: async () => {
      await getAccessToken({ companyId: selectedCompany.id, isAdmin: true });
      setOpenSuccessModal(true);
    },
    onError: () =>
      dispatchToast({
        content: "Sentimos muito, ocorreu um erro. Tente novamente mais tarde",
        type: "error",
      }),
  });

  const createBilletCustomer = trpc.createBilletCustomer.useMutation({
    onSuccess: () => {
      updateContractSetup.mutate({
        finance: { paymentMethodSelected: "billet" },
      });
    },

    onError: () => {
      dispatchToast({
        content: "Sentimos muito, ocorreu um erro. Tente novamente mais tarde",
        type: "error",
      });
    },
  });

  const validateCreditCard = trpc.validateCreditCard.useMutation({
    onSuccess: () => {
      updateContractSetup.mutate({
        finance: { paymentMethodSelected: "creditCard" },
      });
    },

    onError: () => {
      dispatchToast({
        content: "Sentimos muito, ocorreu um erro. Tente novamente mais tarde",
        type: "error",
      });
    },
  });

  const loading =
    isLoadingV2 ||
    validateCreditCard.isLoading ||
    createBilletCustomer.isLoading ||
    updateContractSetup.isLoading;

  const handleSubmitCreditCard = useCallback(
    async (props: {
      number: string;
      holder_name: string;
      exp_month: string;
      exp_year: string;
      cvv: string;
      options: {
        verify_card: boolean;
      };
      isCompanyAddress: boolean;
      billing_address: {
        line_1: string;
        line_2: string;
        zip_code: string;
        city: string;
        state: string;
        country: string;
      };
      phoneNumber: {
        number: string;
        area_code: string;
      };
      documentNumber: string;
    }) => {
      await validateCreditCard.mutate({
        body: props,
      });
    },
    [setPaymentMethod]
  );

  return (
    <>
      <PageHeader style={{ margin: "0px" }}>
        <StyledPageHeaderColumn>
          <Breadcrumb
            breadcrumbs={[
              {
                onClick: () => {
                  navigate(`/settings`, { state: { tab: 2 } });
                },
                label: "Gerenciar empresa",
                active: true,
              },
              {
                onClick: () => {
                  navigate("/billing", { state: { tab: 2 } });
                },
                label: "Financeiro",
                active: true,
              },
              {
                onClick: () => ({}),
                label: "Alterar cartão",
              },
            ]}
            separator={
              <StyledBreadcrumbSeparator size={24} name="IconChevronRight" />
            }
            style={{ marginBottom: "24px" }}
          />
        </StyledPageHeaderColumn>
      </PageHeader>
      <Grid
        container
        spacing={3}
        style={{ padding: "0px 32px" }}
        ref={refToTop}
      >
        <Grid item md={12}>
          <StyledTitle variant="headline6">
            Alterar método de pagamento
          </StyledTitle>
        </Grid>
        <Grid item md={4}>
          <StyledSecondary variant="headline7" style={{ marginBottom: "8px" }}>
            Confira os detalhes da sua fatura
          </StyledSecondary>
          <StyledText variant="body3">
            Sua fatura será debitada todo dia 5. Eventuais cobranças pendentes
            serão processadas simultâneamente em até 3 horas.
          </StyledText>
        </Grid>
        <Grid item md={6}>
          <>
            <StyledTitle variant="headline7" style={{ fontWeight: 700 }}>
              Escolha seu método de pagamento
            </StyledTitle>
            <StyledText variant="body3" style={{ fontWeight: 400 }}>
              Escolha qual meio de pagamento faz mais sentido para você.
            </StyledText>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                margin: "40px 0px",
              }}
            >
              {paymentMethods.map((payment, index) => {
                return (
                  <React.Fragment key={index}>
                    <PaymentMethodBox
                      paymentMethod={payment.paymentMethodLabel}
                      paymentMethodDescription={
                        payment.paymentMethodDescription
                      }
                      onPaymentMethodSelect={() => {
                        setPaymentMethod(payment.paymentMethod);
                        segment({ track: payment.segment });
                      }}
                      iconName={payment.icon}
                      checked={paymentMethod === payment.paymentMethod}
                      segmentTrack={payment.segment}
                    />
                  </React.Fragment>
                );
              })}
            </div>

            {paymentMethod == "creditCard" && (
              <CreditCardForm
                ref={cardInfosRef}
                onSubmit={handleSubmitCreditCard}
                setDisableByCheck={setAcceptTerms}
                disableByCheck={acceptTerms}
                setOpenContractModal={setOpenConditionsModal}
              />
            )}
            {(paymentMethod == "billet" || paymentMethod == "pix") && (
              <PaymentMethodDescriptionBox
                paymentMethod={paymentMethod}
                setDisableByCheck={setAcceptTerms}
                disableByCheck={acceptTerms}
                setOpenContractModal={setOpenConditionsModal}
              />
            )}
          </>
        </Grid>
      </Grid>

      <ConditionsContract
        documentLink={template}
        onOpen={openConditionsModal}
        setOpenModal={setOpenConditionsModal}
        setChecked={setAcceptTerms}
        isPdf={true}
        segmentClose={() => {
          segment({
            track:
              "company_singupsales_acquision_benefitsexpenseclient_close_clicked",
          });
        }}
        segmentConfirm={() => {
          segment({
            track:
              "company_singupsales_acquision_benefitsexpenseclient_readandaccept_clicked",
          });
        }}
      />

      <SuccessModal
        paymentMethod={paymentMethod}
        title={"Alteração do método de pagamento realizada!"}
        onOpen={openSuccessModal}
        setOpenModal={setOpenSuccessModal}
        route={"/billing"}
      />

      <FooterBar
        disabled={acceptTerms}
        loading={loading}
        nextHandleClick={() => {
          switch (true) {
            case paymentMethod == "creditCard":
              cardInfosRef.current.handleSubmit();
              break;
            case paymentMethod == "billet":
              createBilletCustomer.mutate();
              break;
          }
          segment({
            track:
              "company_configurations_finance_paymentsandbilling_confirm_button_clicked",
          });
        }}
        nextHandleButtonLabel={"Confirmar"}
        nextButtonIcon={"IconCheck"}
        previousHandleClick={() => {
          navigate("/billing", { state: { tab: 2 } });
          segment({
            track:
              "company_configurations_finance_paymentsandbilling_cancel_button_clicked",
          });
        }}
        previousHandleButtonLabel={"Cancelar"}
      />
    </>
  );
};

export { CreditCardPage };
