import { Icons, LinkButton, Tooltip } from "@flash-tecnologia/hros-web-ui-v2";
import { useNavigate } from "react-router-dom";
import {
  StyledBox,
  StyledTitle,
  StyledText,
  StyledDiv,
  StyledIcon,
} from "./styled";
//just for deploy

const SubscriptionBox = ({
  date,
  employees,
  price,
  type,
  segment,
  url,
}: {
  date: string;
  employees: any;
  price: string;
  type: string;
  segment?: any;
  url?: string;
}) => {
  const navigate = useNavigate();

  const cardTypes = {
    pending: {
      text: "Faturas atrasadas",
      buttonText: "Alterar método de pagamento",
      tooltipText:
        "Entre em contato com seu banco ou tente novamente com outro cartão.",
      icon: "IconAlertTriangle",
    },
    paymentError: {
      text: "Erro no pagamento",
      buttonText: "Alterar método de pagamento",
      tooltipText:
        "Entre em contato com seu banco ou tente novamente com outro cartão.",
      icon: "IconAlertTriangle",
    },
    processing: {
      text: "Em processamento",
      tooltipText: "Os pagamentos das faturas estão sendo processados.",
      icon: "IconClockHour4",
    },
    closed: {
      text: "Fatura fechada",
      buttonText: "Ver detalhes",
      tooltipText: "",
      url: url,
      icon: "",
    },
  };
  return (
    <StyledBox error={type == "pending" || type == "paymentError"}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          alignItems: "center",
        }}
      >
        {cardTypes[type]?.icon != "" && (
          <StyledIcon
            type={type}
            name={cardTypes[type]?.icon}
            fill="transparent"
            size={12}
          />
        )}
        <StyledTitle variant="body3" style={{ fontWeight: 700 }}>
          {cardTypes[type]?.text}
        </StyledTitle>
        {cardTypes[type]?.tooltipText != "" && (
          <Tooltip
            title={cardTypes[type]?.tooltipText}
            placement={"right-start"}
          >
            <button style={{ cursor: "default" }}>
              <Icons
                name="IconInfoCircle"
                fill="transparent"
                color="#83727D"
                size={16}
              />
            </button>
          </Tooltip>
        )}
      </div>

      <StyledDiv>
        <StyledText variant="body3">Data de cobrança: </StyledText>
        <StyledTitle
          variant="body3"
          style={{ fontWeight: 700, marginLeft: "3px" }}
        >
          {date}
        </StyledTitle>
      </StyledDiv>
      <StyledDiv>
        <StyledTitle
          variant="body3"
          style={{ fontWeight: 700, marginRight: "3px" }}
        >
          {employees}
        </StyledTitle>
        <StyledText variant="body3">pessoas ativadas</StyledText>
      </StyledDiv>
      <StyledDiv>
        <StyledTitle
          variant="body3"
          style={{ fontWeight: 700, marginRight: "3px" }}
        >
          {price}
        </StyledTitle>
        <StyledText variant="body3">valor da assinatura</StyledText>
      </StyledDiv>
      {cardTypes[type]?.buttonText && (
        <LinkButton
          variant="primary"
          onClick={() => {
            navigate(url as string);
          }}
        >
          {cardTypes[type]?.buttonText}
        </LinkButton>
      )}
    </StyledBox>
  );
};

export { SubscriptionBox };
