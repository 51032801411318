import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark2};
`;
const StyledText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
`;

export { StyledTitle, StyledText };
