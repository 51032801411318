export const paymentMethods = [
  {
    paymentMethod: "creditCard",
    paymentMethodLabel: "Novo cartão de crédito",
    paymentMethodDescription:
      "Com o cartão de crédito a cobrança será feita automaticamente na data de cobrança.",
    icon: "IconCreditCard",
    segment:
      "company_configurations_finance_paymentsandbilling_newcreditcard_option_clicked",
  },
  // {
  //   paymentMethod: "Pix",
  //   paymentMethodDescription:"Com o Pix você tem pagamento instantâneo que pode ser feito em poucos segundos. É rápido e seguro.",
  //   icon:"IconPix"
  // },
  {
    paymentMethod: "billet",
    paymentMethodLabel: "Boleto bancário",
    paymentMethodDescription:
      "Pode ser pago através de uma conta ou agência bancária e tem prazo de compensação de até 3 dias úteis.",
    icon: "IconBarcode",
    segment:
      "company_configurations_finance_paymentsandbilling_boleto_option_clicked",
  },
];
