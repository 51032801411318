import {
  Button,
  LinkButton,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Divider } from "@mui/material";
import {
  ErrorText,
  HelperTextContainer,
  StyledAdresseeDiv,
  StyledContainer,
  StyledHeaderDiv,
  StyledIcon,
  StyledIcon2,
  StyledIconButton,
  StyledModal,
  StyledSubtitle,
  StyledTextField,
  StyledTitle,
} from "./styled";

import { useFormik } from "formik";
import { dispatchToast, isValidEmail } from "@utils";
import { trpc } from "@api/client";
import { useEffect } from "react";

export const EmailModal = ({
  onOpen,
  setOpenEmailModal,
  billingEmails,
}: {
  onOpen: boolean;
  setOpenEmailModal: any;
  billingEmails?: any;
}) => {
  const upsertBillingEmails = trpc.upsertBillingEmails.useMutation({
    onSuccess: () => {
      setOpenEmailModal(false);
      dispatchToast({
        type: "success",
        content: "E-mails atualizados com sucesso",
      });
    },
    onError: () => {
      dispatchToast({
        content: "Sentimos muito, ocorreu um erro. Tente novamente mais tarde",
        type: "error",
      });
    },
  });

  useEffect(() => {
    if (billingEmails) {
      const { value } = billingEmails.find((e) => {
        return e.type == "emailTo";
      });
      if (value.length) {
        formik.setFieldValue("email", value);
      }

      let emailsCC = "";

      for (const email of billingEmails) {
        if (email.type === "emailCc") {
          if (emailsCC.length > 0) {
            emailsCC += ", ";
          }
          emailsCC += email.value;
        }
      }
      if (emailsCC.length) {
        formik.setFieldValue("cc", emailsCC);
      }
    }
  }, [billingEmails]);

  const formik = useFormik({
    initialValues: {
      email: "",
      cc: "",
    },
    validate: (values) => {
      const errors: any = {};

      if (!values.email) {
        errors.email = "O campo de Destinatário é obrigatório.";
      } else if (!isValidEmail(values?.email)) {
        errors.email = "Por favor, insira um endereço de email válido.";
      }

      return errors;
    },
    onSubmit: ({ email, cc }) => {
      const emailArray = cc
        .split(/[!#$%^&*()+=\[\]{}|\\;:'",<>/?\s]+/)
        .filter((email) => email)
        .map((email) => ({ type: "emailCc", value: email }));
      emailArray.push({ value: email, type: "emailTo" });

      upsertBillingEmails.mutate(emailArray);
    },
  });

  const modalHeader = (
    <StyledHeaderDiv>
      <StyledIconButton
        size="small"
        variant="line"
        icon="IconX"
        onClick={() => setOpenEmailModal(false)}
      />

      <Typography variant="headline6">Envio de e-mails automáticos</Typography>
      <StyledSubtitle variant="body3">
        Insira os e-mails para os quais você irá enviar as notas fiscais e
        faturas sobre seu plano.
      </StyledSubtitle>
    </StyledHeaderDiv>
  );

  const modalFooter = (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "space-around",
        padding: "32px 0px",
        flexDirection: "row-reverse",
        alignItems: "center",
        borderTop: "1px solid #EBE6E9",
      }}
    >
      <Button
        variant="primary"
        size="large"
        loading={upsertBillingEmails?.isLoading}
        onClick={() => {
          formik.handleSubmit();
        }}
      >
        Confirmar
      </Button>

      <div>
        <LinkButton variant="primary" onClick={() => setOpenEmailModal(false)}>
          Cancelar
        </LinkButton>
      </div>
    </div>
  );

  return (
    <StyledModal
      header={modalHeader}
      footer={modalFooter}
      open={onOpen}
      onClose={() => ({})}
    >
      <StyledContainer>
        <StyledTitle variant="body3" style={{ fontWeight: 700 }}>
          Preencha o e-mail principal e caso queira adicione mais em cópia
        </StyledTitle>
        <StyledTextField
          label="Destinatário*"
          id={"email"}
          name={"email"}
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
        />
        {formik?.touched?.email && formik?.errors?.email && (
          <HelperTextContainer>
            <StyledIcon2 size={15} name="IconAlertCircle" fill="none" />
            <ErrorText variant="body4">{formik.errors.email}</ErrorText>
          </HelperTextContainer>
        )}

        <Divider />
        <StyledAdresseeDiv>
          <StyledSubtitle variant="body4" style={{ fontWeight: 600 }}>
            Destinatários em cópia
          </StyledSubtitle>
          <StyledTextField
            label="Em cópia"
            id={"cc"}
            name={"cc"}
            value={formik.values.cc}
            onChange={formik.handleChange}
            error={formik.touched.cc && Boolean(formik.errors.cc)}
          />
        </StyledAdresseeDiv>
      </StyledContainer>
    </StyledModal>
  );
};
