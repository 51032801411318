import {
  Button,
  Typography,
  PDFViewer,
  Icons,
} from "@flash-tecnologia/hros-web-ui-v2";
import {
  StyledModal,
  StyledSubtitle,
  StyledContainer,
  StyledLinkButton,
  StyledIconButton,
} from "./styled";

const ConditionsContract = ({
  onOpen,
  setOpenModal,
  setChecked,
  segmentConfirm,
  segmentClose,
  documentLink,
  isPdf = false,
}: {
  onOpen: any;
  setOpenModal: any;
  setChecked: any;
  segmentConfirm?: any;
  segmentClose?: any;
  documentLink: any;
  isPdf: boolean;
}) => {
  const modalHeader = (
    <StyledIconButton
      size="small"
      variant="line"
      icon="IconX"
      onClick={() => setOpenModal(false)}
    />
  );
  return (
    <StyledModal header={modalHeader} open={onOpen} onClose={() => ({})}>
      <StyledContainer>
        <Typography
          variant="headline6"
          style={{ marginTop: "8px", marginBottom: "24px", width: "40%" }}
        >
          Condições Comerciais Flash People
        </Typography>
        <StyledSubtitle variant="body3">
          Para confirmar sua assinatura, você deve ler atentamente e aceitar aos
          Termos e Condições Comerciais do Contrato.
        </StyledSubtitle>

        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            marginBottom: "20px",
          }}
        >
          {isPdf ? (
            <PDFViewer
              style={{ borderRadius: 8.5 }}
              width="100%"
              height="470px"
              src={
                documentLink ??
                "https://drive.google.com/file/d/10-PvCh4uy6eMGN9a2eUdDUalwaQF3ESz/preview"
              }
              options={{
                showToolbar: true,
                fit: "height",
                page: 1,
              }}
            />
          ) : (
            <div dangerouslySetInnerHTML={{ __html: documentLink }} />
          )}
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <StyledLinkButton
            variant="primary"
            style={{ alignSelf: "center" }}
            onClick={() => {
              segmentClose && segmentClose();
              setOpenModal(false);
            }}
          >
            Fechar
          </StyledLinkButton>
          <Button
            variant="primary"
            size="large"
            style={{ width: "198px", height: "60px" }}
            onClick={() => {
              segmentConfirm && segmentConfirm();
              setChecked(true);
              setOpenModal(false);
            }}
          >
            Li e aceito{" "}
            <Icons name={"IconCheck"} fill="transparent" color={"#FFF"} />
          </Button>
        </div>
      </StyledContainer>
    </StyledModal>
  );
};

export { ConditionsContract };
